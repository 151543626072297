import { combineReducers, createStore, applyMiddleware } from 'redux'
import sessionReducer from '@/store/modules/session'
import thunk from 'redux-thunk'
import { composeWithDevTools } from 'redux-devtools-extension'

interface Reducer {
  session: any
}
const reducer = combineReducers<Reducer>({
  session: sessionReducer
})

// redux-devtools-extensionとredux-thunkの導入
const enhancer =
  process.env.NODE_ENV === 'development'
    ? composeWithDevTools(applyMiddleware(thunk))
    : applyMiddleware(thunk)

export type State = ReturnType<typeof reducer>

export default () => createStore(reducer, enhancer)
