export interface State {
  session: boolean
}

/**
 * Action Types
 */
export const SET_SESSION = 'SET_SESSION' as const
export const RESET_STORE = 'RESET_STORE' as const

/**
 * Actions
 */
export const setSession = () => {
  return (dispatch: Function) => {
    const session = true
    dispatch({
      type: SET_SESSION,
      payload: { session }
    })
  }
}

export const resetStore = () => ({
  type: RESET_STORE
})

/**
 * Reducer
 */
const initialState: State = {
  session: false
}
export default (state: State = initialState, action: any) => {
  const payload = action.payload || {}
  switch (action.type) {
    case SET_SESSION: {
      return {
        session: payload.session
      }
    }
    case RESET_STORE:
      return initialState
    default:
      return state
  }
}
